@media only screen and (min-width: 350px) {
  #top {
    display: flex;
    flex-direction: row;
  }
  
  #logo {
    width: 100px;
  }
  
  /* #title {
    font-size: 32px;
  } */
  
  #RubberDuck {
    width: 100px;
    margin-right: 100px;
    margin-top: 75px;
  }
  
  .section {
    text-align: center;
  }
  
  /* #whoHeader {
    font-size: 42px;
  } */
  
  #whoText {
    /* font-size: 24px; */
    width: 75%;
    margin: auto auto;
  }
  
  #teamBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .teamMember {
    width: 50%;
  }
  
  #teamHeader {
    font-size: 42px;
  }
  
  
  .name {
    font-size: 32px;
  }
  
  .description {
    font-size: 24px;
  }
  
  .images {
    width: 200px;
  }

}

@media only screen and (min-width: 500px) {
  #top {
    text-align: center;
  }
}

@media only screen and (min-width: 800px) {
  #logo {
    width: 200px;
  }

  #title {
    margin: auto auto auto 22%;
    font-size: 54px;
  }

  #top {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  #whoHeader {
    font-size: 48px;
  }

  #whoText {
    font-size: 32px;
  }

  #teamBox {
    flex-direction: row;
  }

  .images {
    width: 300px;
  }

  #logan {
    height: 75%;
  }

}

@media only screen and (min-width: 1000px) {

  #logan {
    width: 500px;
    margin-bottom: 150px;
  }

}